var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Új hozzáadása"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Horgász"}},[_c('validation-provider',{attrs:{"name":"horgász","rules":"required","vid":"fisherman"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.fishermanOptions},model:{value:(_vm.formData.fisherman),callback:function ($$v) {_vm.$set(_vm.formData, "fisherman", $$v)},expression:"formData.fisherman"}}),_c('b-input-group-append',[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.form-record-catch-new-fisherman-modal",modifiers:{"form-record-catch-new-fisherman-modal":true}}],staticClass:"btn btn-secondary"},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Halfaj"}},[_c('validation-provider',{attrs:{"name":"halfaj","rules":"required","vid":"fishType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.fishTypeOptions},model:{value:(_vm.formData.fishType),callback:function ($$v) {_vm.$set(_vm.formData, "fishType", $$v)},expression:"formData.fishType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Hely"}},[_c('validation-provider',{attrs:{"name":"hely","rules":"required","vid":"location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.locationOptions},model:{value:(_vm.formData.location),callback:function ($$v) {_vm.$set(_vm.formData, "location", $$v)},expression:"formData.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Csali"}},[_c('validation-provider',{attrs:{"name":"csali","rules":"required","vid":"bait"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.baitOptions},model:{value:(_vm.formData.bait),callback:function ($$v) {_vm.$set(_vm.formData, "bait", $$v)},expression:"formData.bait"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.formData.onlyYears)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Fogás ideje"}},[_c('validation-provider',{attrs:{"name":"fogás ideje","rules":"required","vid":"catchTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.catchTime),callback:function ($$v) {_vm.$set(_vm.formData, "catchTime", $$v)},expression:"formData.catchTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3510429078)})],1)],1):_vm._e(),(_vm.formData.onlyYears)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Fogás ideje"}},[_c('validation-provider',{attrs:{"name":"fogás ideje","rules":"required","vid":"catchTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"1000","max":"9999","placeholder":"ÉÉÉÉ","autocomplete":"off","required":""},model:{value:(_vm.formData.catchTime),callback:function ($$v) {_vm.$set(_vm.formData, "catchTime", $$v)},expression:"formData.catchTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2162310516)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"csak év","vid":"onlyYears"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.onlyYears),callback:function ($$v) {_vm.$set(_vm.formData, "onlyYears", $$v)},expression:"formData.onlyYears"}},[_vm._v(" Csak év ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Tömeg"}},[_c('validation-provider',{attrs:{"name":"tömeg","rules":"required","vid":"weight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","step":"0.01"},model:{value:(_vm.formData.weight),callback:function ($$v) {_vm.$set(_vm.formData, "weight", $$v)},expression:"formData.weight"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v("kg")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Hossz"}},[_c('validation-provider',{attrs:{"name":"hossz","rules":"","vid":"length"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formData.length),callback:function ($$v) {_vm.$set(_vm.formData, "length", $$v)},expression:"formData.length"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v("cm")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Kerület"}},[_c('validation-provider',{attrs:{"name":"kerület","rules":"","vid":"perimeter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formData.perimeter),callback:function ($$v) {_vm.$set(_vm.formData, "perimeter", $$v)},expression:"formData.perimeter"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v("cm")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"visszaengedve","vid":"released"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.released),callback:function ($$v) {_vm.$set(_vm.formData, "released", $$v)},expression:"formData.released"}},[_vm._v(" Visszaengedve? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Beszámoló"}},[_c('validation-provider',{attrs:{"name":"beszámoló","rules":"","vid":"report"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor',{model:{value:(_vm.formData.report),callback:function ($$v) {_vm.$set(_vm.formData, "report", $$v)},expression:"formData.report"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Halkövetés"}},[_c('validation-provider',{attrs:{"name":"halkövetés","rules":"","vid":"tracking"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.formData.tracking),callback:function ($$v) {_vm.$set(_vm.formData, "tracking", $$v)},expression:"formData.tracking"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Verseny"}},[_c('validation-provider',{attrs:{"name":"verseny","rules":"","vid":"competition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.formData.competition),callback:function ($$v) {_vm.$set(_vm.formData, "competition", $$v)},expression:"formData.competition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"tiltott képek","vid":"hiddenPhotos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.hiddenPhotos),callback:function ($$v) {_vm.$set(_vm.formData, "hiddenPhotos", $$v)},expression:"formData.hiddenPhotos"}},[_vm._v(" Tiltott képek? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"pr képek","vid":"prPhotos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.prPhotos),callback:function ($$v) {_vm.$set(_vm.formData, "prPhotos", $$v)},expression:"formData.prPhotos"}},[_vm._v(" PR képek? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1),_c('new-fisherman',{on:{"refresh-fishermen":_vm.refreshFishermen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }