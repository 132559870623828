<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group
                label="Horgász"
            >
              <validation-provider
                  #default="{ errors }"
                  name="horgász"
                  rules="required"
                  vid="fisherman"
              >
                <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                      v-model="formData.fisherman"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :clearable="false"
                      :options="fishermanOptions"
                  />
                  <b-input-group-append>
                    <b-link
                        class="btn btn-secondary"
                        v-b-modal.form-record-catch-new-fisherman-modal
                    >
                      <feather-icon icon="PlusIcon"></feather-icon>
                    </b-link>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
                label="Halfaj"
            >
              <validation-provider
                  #default="{ errors }"
                  name="halfaj"
                  rules="required"
                  vid="fishType"
              >
                <v-select
                    v-model="formData.fishType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :clearable="false"
                    :options="fishTypeOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Hely"
            >
              <validation-provider
                  #default="{ errors }"
                  name="hely"
                  rules="required"
                  vid="location"
              >
                <v-select
                    v-model="formData.location"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :clearable="false"
                    :options="locationOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Csali"
            >
              <validation-provider
                  #default="{ errors }"
                  name="csali"
                  rules="required"
                  vid="bait"
              >
                <v-select
                    v-model="formData.bait"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :clearable="false"
                    :options="baitOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="!formData.onlyYears" md="12">
            <b-form-group
                label="Fogás ideje"
            >
              <validation-provider
                  #default="{ errors }"
                  name="fogás ideje"
                  rules="required"
                  vid="catchTime"
              >
                <flat-pickr
                    v-model="formData.catchTime"
                    class="form-control"
                    :config="flatPickRConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="formData.onlyYears" md="12">
            <b-form-group
                label="Fogás ideje"
            >
              <validation-provider
                  #default="{ errors }"
                  name="fogás ideje"
                  rules="required"
                  vid="catchTime"
              >
                <b-form-input
                    v-model="formData.catchTime"
                    type="number"
                    min="1000"
                    max="9999"
                    placeholder="ÉÉÉÉ"
                    autocomplete="off"
                    required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1">
            <validation-provider
                #default="{ errors }"
                name="csak év"
                vid="onlyYears"
            >
              <b-form-checkbox
                  v-model="formData.onlyYears"
                  :state="errors.length > 0 ? false:null"
              >
                Csak év
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Tömeg"
            >
              <validation-provider
                  #default="{ errors }"
                  name="tömeg"
                  rules="required"
                  vid="weight"
              >
                <b-input-group>
                  <b-form-input
                      v-model="formData.weight"
                      type="number"
                      step="0.01"
                  />
                  <b-input-group-append>
                    <b-input-group-text>kg</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Hossz"
            >
              <validation-provider
                  #default="{ errors }"
                  name="hossz"
                  rules=""
                  vid="length"
              >
                <b-input-group>
                  <b-form-input
                      v-model="formData.length"
                      type="number"
                  />
                  <b-input-group-append>
                    <b-input-group-text>cm</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Kerület"
            >
              <validation-provider
                  #default="{ errors }"
                  name="kerület"
                  rules=""
                  vid="perimeter"
              >
                <b-input-group>
                  <b-form-input
                      v-model="formData.perimeter"
                      type="number"
                  />
                  <b-input-group-append>
                    <b-input-group-text>cm</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1">
            <validation-provider
                #default="{ errors }"
                name="visszaengedve"
                vid="released"
            >
              <b-form-checkbox
                  v-model="formData.released"
                  :state="errors.length > 0 ? false:null"
              >
                Visszaengedve?
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Beszámoló"
            >
              <validation-provider
                  #default="{ errors }"
                  name="beszámoló"
                  rules=""
                  vid="report"
              >
                <editor
                    v-model="formData.report"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Halkövetés"
            >
              <validation-provider
                  #default="{ errors }"
                  name="halkövetés"
                  rules=""
                  vid="tracking"
              >
                <b-form-input
                    v-model="formData.tracking"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Verseny"
            >
              <validation-provider
                  #default="{ errors }"
                  name="verseny"
                  rules=""
                  vid="competition"
              >
                <b-form-input
                    v-model="formData.competition"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="tiltott képek"
                vid="hiddenPhotos"
            >
              <b-form-checkbox
                  v-model="formData.hiddenPhotos"
                  :state="errors.length > 0 ? false:null"
              >
                Tiltott képek?
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="pr képek"
                vid="prPhotos"
            >
              <b-form-checkbox
                  v-model="formData.prPhotos"
                  :state="errors.length > 0 ? false:null"
              >
                PR képek?
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <new-fisherman
        @refresh-fishermen="refreshFishermen"
    />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BInputGroup,
  BInputGroupAppend, BInputGroupText, BLink
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import newFisherman from "@/views/pages/_components/_modal/record-catch/newFisherman";
import editor from '@/views/pages/_components/editor.vue'
import labels from "@/libs/datepicker/labels";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BLink,
    flatPickr,
    editor,
    vSelect,
    cancelAndGOTOPreviousPage,
    newFisherman,
  },
  data() {
    return {
      role: 'record_catch',
      formData: {
        fisherman: '',
        fishType: '',
        location: '',
        bait: '',
        catchTime: '',
        onlyYears: false,
        weight: '',
        length: '',
        perimeter: '',
        report: '',
        released: false,
        tracking: '',
        competition: '',
        hiddenPhotos: false,
        prPhotos: false,
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      fishermanOptions: [],
      fishTypeOptions: [],
      locationOptions: [],
      baitOptions: [],
      required,
      labels,
    }
  },
  created() {
    this.refreshFishermen();
    this.$store.dispatch('fetchFishTypesForSelect').then(response => {
      this.fishTypeOptions = response

      if (this.fishTypeOptions.length) {
        this.formData.fishType = this.fishTypeOptions[0]
      }
    })

    this.$store.dispatch('fetchLocationsForSelect').then(response => {
      this.locationOptions = response

      if (this.locationOptions.length) {
        this.formData.location = this.locationOptions[0]
      }
    })

    this.$store.dispatch('fetchBaitsForSelect').then(response => {
      this.baitOptions = response

      if (this.baitOptions.length) {
        this.formData.bait = this.baitOptions[0]
      }
    })
  },
  methods: {
    refreshFishermen() {
      this.$store.dispatch('fetchFishermenForSelect').then(response => {
        this.fishermanOptions = response

        if (this.fishermanOptions.length) {
          this.formData.fisherman = this.fishermanOptions[0]
        }
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = {...this.formData}
          fd.fisherman = this.formData.fisherman.value
          fd.fishType = this.formData.fishType.value
          fd.location = this.formData.location.value
          fd.bait = this.formData.bait.value

          this.$store.dispatch('storeRecordCatch', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canEditPermission(this.role)
                ? this.$router.push({name: 'modify-record-catch', params: {id: response.data.entity.id}})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.input-group .v-select {
  flex: 1 1 auto;
}

[dir="ltr"] .input-group:not(.has-validation) .vs__dropdown-toggle:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
